<template>
  <div>
    <Form
      ref="storedContractModel"
      :model="storedContractModel"
      autocomplete="on"
      :rules="ruleValidate"
      label-position="top"
    >
      <Row :gutter="8" class="m-b-10">
        <i-col span="6">
          <FormItem label="乙方" prop="secondPartyId">
            <i-select
              v-model="storedContractModel.secondPartyId"
              size="small"
              placeholder="乙方"
            >
              <i-option
                v-for="item in secondPartyArray"
                :value="item.id"
                :key="'dct_' + item.id"
                >{{ item.name }}
              </i-option>
            </i-select>
          </FormItem>
        </i-col>
        <i-col span="6">
          <FormItem label="合同名称" prop="contractName">
            <Input
              size="small"
              v-model="storedContractModel.contractName"
              placeholder="合同名称"
            />
          </FormItem>
        </i-col>
        <i-col span="6">
          <FormItem label="储值有效期开始日期" prop="startDate">
            <DatePicker
              size="small"
              v-model="storedContractModel.startDate"
              placeholder="开始日期"
              style="width: 100%"
            ></DatePicker>
          </FormItem>
        </i-col>
        <i-col span="6">
          <FormItem label="储值有效期结束日期" prop="endDate">
            <DatePicker
              size="small"
              placeholder="结束日期"
              v-model="storedContractModel.endDate"
              style="width: 100%"
            ></DatePicker>
          </FormItem>
        </i-col>

        <i-col span="24">
          <FormItem
            label="媒体储值明细(电子媒体储值数量单位为：秒，其他媒体为：块*天)"
          >
            <Row
              :gutter="8"
              class="m-b-10"
              v-for="(pro, index) in storedProductArray"
              :key="'pro_' + index"
            >
              <i-col span="3">
                <i-select
                  v-model="pro.productId"
                  size="small"
                  filterable
                  placeholder="产品名称"
                >
                  <i-option
                    v-for="item in productArray"
                    :value="item.id"
                    :key="'dct_' + item.id"
                    >{{ item.name }}
                  </i-option>
                </i-select>
              </i-col>
              <i-col span="3">
                <Input
                  size="small"
                  number
                  v-model="pro.quantity"
                  placeholder="储值数量"
                />
              </i-col>
              <i-col span="3">
                <Input
                  size="small"
                  prefix="logo-yen"
                  number
                  v-model="pro.totalUsePrice"
                  placeholder="刊例价"
                />
              </i-col>
              <i-col span="3">
                <Input
                  size="small"
                  prefix="logo-yen"
                  number
                  v-model="pro.signUsePrice"
                  placeholder="签约价"
                />
              </i-col>
              <i-col span="3">
                <DatePicker
                  size="small"
                  placeholder="开始日期"
                  v-model="pro.resourceStartDate"
                  style="width: 100%"
                ></DatePicker>
              </i-col>
              <i-col span="3">
                <DatePicker
                  size="small"
                  placeholder="结束日期"
                  v-model="pro.resourceEndDate"
                  style="width: 100%"
                ></DatePicker>
              </i-col>
              <i-col span="3">
                <Input
                  size="small"
                  number
                  v-model="pro.resourceNum"
                  placeholder="点位数量"
                />
              </i-col>
              <i-col span="3">
                <a @click="DeleteStoredItem(index)">删除</a>
              </i-col>
            </Row>
          </FormItem>
        </i-col>
        <i-col span="24" class="text-right">
          <a @click="AddStoredItem">+ 新增储值媒体</a>
        </i-col>
        <i-col span="24">
          <FormItem label="服务储值明细">
            <Row
              :gutter="8"
              class="m-b-10"
              v-for="(service, index) in storedServiceArray"
              :key="'service_' + index"
            >
              <i-col span="3">
                <i-select
                  v-model="service.id"
                  size="small"
                  filterable
                  placeholder="服务名称"
                  @on-change="serviceFeeChange"
                >
                  <i-option
                    v-for="item in serviceArray"
                    :value="item.id"
                    :key="'dct_' + item.id"
                    >{{ item.name }}
                  </i-option>
                </i-select>
              </i-col>
              <i-col span="3">
                <Input
                  size="small"
                  number
                  v-model="service.signServiceQuantity"
                  placeholder="储值数量"
                >

                </Input>
              </i-col>
              <i-col span="3">
                <Input
                  size="small"
                  prefix="logo-yen"
                  number
                  v-model="service.price"
                  placeholder="刊例单价"
                />
              </i-col>
              <i-col span="3">
                <Input
                  size="small"
                  prefix="logo-yen"
                  number
                  v-model="service.signUnitPrice"
                  placeholder="签约单价"
                />
              </i-col>

              <!-- <i-col span="3">
              <Input size="small" prefix="logo-yen" number v-model="pro.resourceNum"  placeholder="点位数量" />
            </i-col> -->
              <i-col span="2">
                <a @click="DeleteStoredServiceItem(index)">删除</a>
              </i-col>
            </Row>
          </FormItem>
        </i-col>
        <i-col span="24" class="text-right">
          <a @click="AddStoredServiceItem">+ 新增储值服务</a>
        </i-col>
      </Row>
    </Form>
  </div>
</template>

<script>
import { ParseDate } from '@/utils/dateFormat'
import { getAllProduct } from '@/api/product/product'
import { commonSkuServiceFeeList } from '@/api/product/serviceItemPrices'
// import { addDevice, updateDevice } from '@/api/product/deviceedit'
/** new api */
import { createStoredContract } from '@/api/scp/storedContract'
export default {
  props: {
    operate: {
      type: Number,
      default: 1
    },
    onSuccess: Function
  },
  data () {
    return {
      productArray: [],
      storedProductArray: [],

      serviceArray: [],
      storedServiceArray: [],

      storedContractModel: {
        contractName: '',
        endDate: '',
        startDate: '',
        secondPartyId: '',
        productListStr: '',
        serviceFeeListStr: ''
      },
      ruleValidate: {
        secondPartyId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        contractName: [{ required: true, message: ' ' }],
        startDate: [{ required: true, message: ' ' }],
        endDate: [{ required: true, message: ' ' }]
      }
    }
  },
  computed: {
    secondPartyArray () {
      return this.$store.state.storedValue.secondPartyArray
    }
  },
  mounted () {
    if (!this.storedContractModel.secondPartyId) {
      this.storedContractModel.secondPartyId = this.secondPartyArray[0].id
    }
    // this.initProductArray()
  },
  methods: {
    initProductArray () {
      getAllProduct({}).then((res) => {
        const array = []
        res.forEach((item) => {
          if (!array.filter((x) => x.id === item.id).length) {
            array.push({ id: item.id, name: item.name })
          }
        })
        this.productArray = array
      })
    },
    DeleteStoredItem (index) {
      this.storedProductArray.splice(index, 1)
    },
    AddStoredItem () {
      this.storedProductArray.push({
        productId: null,
        quantity: null,
        signUsePrice: null,
        totalUsePrice: null,
        resourceStartDate: null,
        resourceEndDate: null,
        resourceNum: null
      })
    },

    serviceFeeChange (e) {
      this.storedServiceArray.filter(x => x.id === e).forEach(item => {
        const defaultService = this.serviceArray.filter(x => x.id === e)[0]
        item.price = defaultService.price
        item.signUnitPrice = defaultService.price
        item.name = defaultService.name
        item.type = defaultService.type
        item.typeName = defaultService.typeName
        item.unit = defaultService.unit
        item.unitName = defaultService.unitName
      })
    },

    initServiceArray () {
      commonSkuServiceFeeList({}).then((res) => {
        const array = []
        res.forEach((item) => {
          if (!array.filter((x) => x.id === item.id).length) {
            array.push(item)
          }
        })
        this.serviceArray = array
      })
    },
    DeleteStoredServiceItem (index) {
      this.storedServiceArray.splice(index, 1)
    },
    AddStoredServiceItem () {
      this.storedServiceArray.push({
        productId: null,
        quantity: null,
        signUsePrice: null,
        totalUsePrice: null,
        resourceStartDate: null,
        resourceEndDate: null,
        resourceNum: null
      })
    },

    handleSubmit () {
      this.$refs.storedContractModel.validate((valid) => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示',
            content: '确认信息无误，并提交？',
            onOk: () => {
              this.storedContractModel.startDate = ParseDate(
                this.storedContractModel.startDate
              )
              this.storedContractModel.endDate = ParseDate(
                this.storedContractModel.endDate
              )

              let errline = 0

              this.storedProductArray.forEach((item) => {
                item.resourceStartDate = ParseDate(item.resourceStartDate)
                item.resourceEndDate = ParseDate(item.resourceEndDate)

                if (
                  item.productId &&
                  parseInt(item.quantity) &&
                  parseFloat(item.totalUsePrice) &&
                  parseFloat(item.signUsePrice) &&
                  parseInt(item.resourceNum)
                ) {
                  errline += 0
                } else {
                  errline += 1
                }
              })

              this.storedServiceArray.forEach((item) => {
                if (
                  item.id &&
                  parseInt(item.signServiceQuantity) &&
                  parseFloat(item.price) &&
                  parseFloat(item.signUnitPrice)
                ) {
                  errline += 0
                } else {
                  errline += 1
                }
              })

              if (!errline) {
                this.storedContractModel.productListStr = JSON.stringify(
                  this.storedProductArray
                )
                this.storedContractModel.serviceFeeListStr = JSON.stringify(
                  this.storedServiceArray
                )
                createStoredContract(this.storedContractModel).then((res) => {
                  if (res && res.errcode === 0) {
                    this.onSuccess()
                    this.$Notice.success({ desc: '操作成功' })
                  }
                })
              } else {
                this.$Notice.error({ desc: '请完整填写储值明细信息' })
              }
            }
          })
        } else {
          this.$Notice.error({ desc: '请先完善必填信息' })
        }
      })
    }
  },
  watch: {
    'storedContractModel.secondPartyId': function () {
      this.storedProductArray = []
      this.storedProductArray.push({
        productId: null,
        quantity: null,
        signUsePrice: null,
        totalUsePrice: null
      })

      this.storedServiceArray = []
      this.storedServiceArray.push({
        id: null,
        signServiceQuantity: null,
        price: null,
        signUnitPrice: null
      })

      this.initProductArray()
      this.initServiceArray()
    }
  }
}
</script>
