<style>
.serviceRemark_detail{
    min-width: 200px;
    /* height: 50px; */
    padding: 5px;
    border: 1px solid #5cadff;
    border-radius: 3px;
    color: #808695;
    font-weight: 400;

    position: fixed;
    top: 325px;
    background-color: papayawhip;
    z-index: 999;
}
</style>
<template>
  <div>
    <Modal v-model="show" title="合同详情" width="800" :footer-hide="true">
      <!-- <p slot="header" class="text-center">设备详情</p> -->
      <div>
        <Row class="p-b-10">
          <i-col span="8">
            <span class="title">合同名称</span>{{ contractInfo.name }}
          </i-col>
          <i-col span="8">
            <span class="title">合同编号</span>{{ contractInfo.code }}
          </i-col>
          <i-col span="8">
            <span class="title">乙方</span>{{ contractInfo.secondPartyName }}
          </i-col>
        </Row>
        <Row class="p-b-10">
          <i-col span="8">
            <span class="title">有效期</span>{{ contractInfo.startDate }} 至
            {{ contractInfo.endDate }}
          </i-col>

          <i-col span="8">
            <span class="title">合同总签约额</span
            >{{ formatMoney(contractInfo.amount) }}
          </i-col>
          <i-col span="8">
            <span class="title">状态</span>{{ contractInfo.statusName }}
          </i-col>
        </Row>
        <Row class="p-b-10">

          <i-col span="8">
            <span class="title">创建时间</span>{{ contractInfo.createTime }}
          </i-col>
          <i-col span="8">
            <span class="title">创建人</span>{{ contractInfo.createUserName }}
          </i-col>
        </Row>
      </div>
      <div>
        <h4 class="workplatform-title m-t-10 m-b-10">储值媒体明细</h4>
        <Table
          size="small"
          stripe
          :data="contractInfo.productList"
          :columns="tableColumns"
        ></Table>
      </div>
      <div>
        <h4 class="workplatform-title m-t-10 m-b-10">储值服务明细</h4>
        <Table
          size="small"
          stripe
          :data="contractInfo.serviceFeeDetailVOList"
          :columns="serviceTableColumns"
        ></Table>
      </div>
    </Modal>
    <Modal v-model="showResourceList" title="储值资源清单" width="800" :footer-hide="true">
      <div>
        <!-- <h4 class="workplatform-title m-t-10 m-b-10">资源清单</h4> -->
        <Table
          size="small"
          stripe
          :data="resourceList"
          :columns="resourceTableColumns"
        ></Table>
        <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="handlePageChanged"
      ></Page>
    </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { getStoredContractDetail, listStoredContractResource } from '@/api/scp/storedContract'
export default {
  components: {},
  props: {
    id: {
      type: Number
    }
  },
  computed: {},
  data () {
    return {
      show: false,

      contractInfo: {},
      list: [],
      tableColumns: [
        { title: '产品名称', align: 'center', key: 'productName' },
        {
          title: '储值数量',
          renderHeader: (h, params) => {
            return h('div', [
              h('span', '库存量'),
              h('Icon', {
                props: { type: 'md-help-circle', size: 18, color: '#2d8cf0', id: 'serviceIon' },
                style: { 'margin-left': '4px', cursor: 'pointer' },
                on: {
                  click: (event) => {
                    if (document.getElementsByClassName('serviceRemark_detail').length < 1) {
                      var serviceDiv = document.createElement('div')
                      serviceDiv.className = 'serviceRemark_detail'

                      // const title = document.createElement('p')
                      // title.innerHTML = '计费时间是指：'
                      // serviceDiv.appendChild(title)

                      const content = document.createElement('p')
                      content.innerHTML = '电子媒体库存单位为：秒，其他媒体为：块*天'
                      serviceDiv.appendChild(content)

                      event.target.parentElement.appendChild(serviceDiv)
                      // 3秒后自动关闭
                      setTimeout(() => {
                        if (serviceDiv.parentNode) {
                          serviceDiv.parentNode.removeChild(serviceDiv)
                        }
                      }, 3000)
                    } else {
                      const serviceDivRemark = document.getElementsByClassName('serviceRemark_detail')
                      serviceDivRemark[0].parentNode.removeChild(serviceDivRemark[0])
                    }
                  }
                }
              })
            ])
          },
          align: 'center',
          key: 'quantity'
        },
        {
          title: '刊例价',
          align: 'center',
          key: 'usePrice',
          render: (h, data) => {
            return h(
              'span',
              {
                style: {
                  color: '#19be6b'
                }
              },
              toMoney(data.row.usePrice)
            )
          }
        },
        {
          title: '签约价',
          align: 'center',
          key: 'signUsePrice',
          render: (h, data) => {
            return h(
              'span',
              {
                style: {
                  color: '#19be6b'
                }
              },
              toMoney(data.row.signUsePrice)
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.query.storedContractFeeDetailId = params.row.id
                      this.query.pageNumber = 1
                      this.resourceList = []
                      this.initStoredContractResourcearray()
                      this.showResourceList = true
                      // listStoredContractResource({
                      //   storedContractFeeDetailId: params.row.id
                      // }).then(res => {
                      //   this.resourceList = res
                      //   this.showResourceList = true
                      // })
                    }
                  }
                },
                '资源清单'
              )
            ])
          }
        }
      ],
      serviceTableColumns: [
        { title: '服务名称', align: 'center', key: 'serviceItemName' },
        {
          title: '储值数量',
          align: 'center',
          key: 'signServiceQuantity'
        },
        {
          title: '刊例单价',
          align: 'center',
          key: 'unitPrice',
          render: (h, data) => {
            return h(
              'span',
              {
                style: {
                  color: '#19be6b'
                }
              },
              toMoney(data.row.unitPrice)
            )
          }
        },
        {
          title: '签约单价',
          align: 'center',
          key: 'signUnitPrice',
          render: (h, data) => {
            return h(
              'span',
              {
                style: {
                  color: '#19be6b'
                }
              },
              toMoney(data.row.signUnitPrice)
            )
          }
        },
        {
          title: '签约总价',
          align: 'center',
          key: 'signUnitPrice',
          render: (h, data) => {
            return h(
              'span',
              {
                style: {
                  color: '#19be6b'
                }
              },
              toMoney(data.row.signServiceAmount)
            )
          }
        }
      ],

      showResourceList: false,

      query: {
        pageSize: 10,
        pageNumber: 1,
        storedContractFeeDetailId: 0
      },
      total: 0,
      resourceList: [],
      resourceTableColumns: [
        { title: '资源编码', align: 'center', key: 'resourceCode' },
        { title: '所属线路', align: 'center', key: 'assetName' },
        { title: '所属站点', align: 'center', key: 'stationName' },
        { title: '所属位置', align: 'center', key: 'positionName' },

        {
          title: '资源档期',
          align: 'center',
          key: 'startDate',
          render: (h, data) => {
            return h('span', data.row.resourceStartDate + '至' + data.row.resourceEndDate)
          }
        }
      ]
    }
  },
  methods: {
    formatMoney (mon) {
      return toMoney(mon)
    },
    showModal (id) {
      getStoredContractDetail({ storedContractId: id }).then((res) => {
        this.contractInfo = res
      })

      this.show = true
    },
    initStoredContractResourcearray () {
      listStoredContractResource(this.query).then(res => {
        this.total = res.totalRow
        this.resourceList = res.list
      })
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.initStoredContractResourcearray()
    }
  }
}
</script>
